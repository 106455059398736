(function () {
  angular.module('kmi.lms.passwords').component('passwordPolicies', {
    template: require('ajs/modules/passwords/components/password-policies.html').default,
    controller: PasswordPoliciesCtrl,
    controllerAs: 'vm',
    bindings: {
      user: '<',
      rules: '=',
      showOnlyFailed: '<?',
      newPasswordRules: '<?',
    },
  });

  function PasswordPoliciesCtrl($scope, passwordPoliciesService, rootScopeService, _, $timeout) {
    const vm = this;

    vm.rulesStatus = {
      failedRules: [],
      completedRules: [],
    };

    vm.$onInit = onInit;
    vm.filterRules = filterRules;
    vm.filterCustomErrorValidation = filterCustomErrorValidation;
    vm.includes = _.includes;

    function onInit() {
      loadPolicies();

      $scope.$on('event:passwordRulesStatusUpdated', function (event, rules) {
        vm.rulesStatus = rules;

        populateRulesStatus();
      });

      $scope.$on('event:passwordValidationProgress', function (event, inProgress) {
        vm.loading = inProgress;

        if (inProgress) {
          clearStatus();
        }
      });

      $scope.$watchGroup(
        ['vm.user.firstName', 'vm.user.lastName', 'vm.user.loginName', 'vm.user.email'],
        function (newValues, oldValues) {
          if (!_.isEqual(newValues, oldValues)) {
            if (vm.eventScheduled) {
              $timeout.cancel(vm.eventScheduled);
              vm.eventScheduled = null;
            }

            vm.eventScheduled = $timeout(function () {
              rootScopeService.broadcast('event:passwordValidationStart');
              vm.eventScheduled = null;
            }, 500);
          }
        },
      );
    }

    function loadPolicies() {
      passwordPoliciesService.getCustomerRules(true).then(function (rules) {
        if (vm.newPasswordRules) {
          rules = _.filter(rules, function (passwordRule) {
            return passwordRule.rule.coversNewPasswords;
          });
        }

        vm.rules = rules;
        populateRulesStatus();
      });
    }

    function clearStatus() {
      vm.rules.forEach(function (rule) {
        delete rule.isValid;
      });
    }

    function populateRulesStatus() {
      clearStatus();

      var userRulesDict = _.keyBy(vm.rules, 'rule.internalName');

      vm.rulesStatus.completedRules.forEach(function (rule) {
        if (userRulesDict[rule]) {
          userRulesDict[rule].isValid = true;
        }
      });

      vm.rulesStatus.failedRules.forEach(function (rule) {
        if (userRulesDict[rule]) {
          userRulesDict[rule].isValid = false;
        }
      });
    }

    function filterCustomErrorValidation(rule) {
      return rule.rule.customErrorValidation;
    }

    function filterRules(rule) {
      if (rule.rule.customErrorValidation) {
        return false;
      }

      if (vm.showOnlyFailed) {
        return rule.isValid === false;
      }

      return true;
    }
  }
})();
