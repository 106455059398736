(function () {
  angular.module('kmi.lms.network').factory('UserNetworkFollower', UserNetworkFollower);

  /* @ngInject */
  function UserNetworkFollower($resource) {
    return $resource(
      '/a/:activityOrigin/user_network/users/:userId/followers/:followerUserId/',
      { userId: '@userId', followerUserId: '@followerUserId', activityOrigin: '@activityOrigin' },
      {},
      { stripTrailingSlashes: false },
    );
  }
})();
