(function () {
  angular.module('kmi.lms.integration').factory('videoHostingProvider', videoHostingProvider);

  /* @ngInject */
  function videoHostingProvider(vimeoVideoService, limelightVideoService) {
    return {
      get: getProvider,
    };

    /**
     * @description
     * Returns video provider by typeID
     * @param typeId
     * @returns {*}
     */
    function getProvider(typeId) {
      if (typeId === 1) {
        return vimeoVideoService;
      }

      if (typeId === 2) {
        return limelightVideoService;
      }

      return null;
    }
  }
})();
