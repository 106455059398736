(function () {
  angular.module('kmi.lms.network').factory('networkRotatorService', networkRotatorService);

  /* @ngInject */
  function networkRotatorService($q, $http, _, networkProfileCompletenessFields) {
    var allViews = {
      profileCompleteness: null,
      suggestedSubscriptions: null,
    };
    var availableViews = [];
    var activeView = null;

    return {
      getNetworkProfileCompleteness: getNetworkProfileCompleteness,
      getRoratorViews: getRoratorViews,
      loadData: loadData,
      getAvailableViews: getAvailableViews,
      getDefaultView: getDefaultView,
      setActiveView: setActiveView,
    };

    /**
     * @description
     * Return percentage of completeness for user profile
     */
    function getNetworkProfileCompleteness(user) {
      var total = networkProfileCompletenessFields.length,
        completed = 0;

      _.each(networkProfileCompletenessFields, function (item) {
        completed += _.isEmpty(user[item]) ? 0 : 1;
      });

      return (completed / total) * 100;
    }

    /**
     * @description
     * Return all existing rotated views
     */
    function getRoratorViews() {
      return allViews;
    }

    /**
     * @description
     * Return all available views
     */
    function getAvailableViews() {
      return availableViews;
    }

    /**
     * @description
     * load all available rotator views and calculate default view. Should call on load component
     */
    function loadData(user, params) {
      // It should be chnaged top separated service that return result for all views
      return $http
        .get(['/a/user/user_network/users/me/rotator-data/'].join(''), { params: { count: 3 } })
        .then(function (response) {
          var suggestedUsers =
            response && response.data && response.data.suggestedUsers ? response.data.suggestedUsers : [];
          // load cached data if exits
          if (params && params.suggestedUsers) {
            suggestedUsers = params.suggestedUsers;
          }

          availableViews = [];
          var percentage = getNetworkProfileCompleteness(user);
          if (percentage >= 100) {
            allViews.profileCompleteness = null;
          } else {
            allViews.profileCompleteness = true;
            availableViews.push('profileCompleteness');
          }

          if (suggestedUsers.count > 0) {
            allViews.suggestedSubscriptions = suggestedUsers;
            //availableViews.push('suggestedSubscriptions');
          } else {
            allViews.suggestedSubscriptions = null;
          }

          // change active view only when we do not need to show old cached data
          if (!activeView && availableViews.length) {
            activeView = availableViews[0];
          } else {
            if (!params || !params.suggestedUsers) {
              if (percentage < 100) {
                activeView = 'profileCompleteness';
              } else {
                var index = _.findIndex(availableViews, function (item) {
                  return item === activeView;
                });
                activeView = availableViews[index === availableViews.length - 1 ? 0 : index + 1];
              }
            }
          }
        });
    }

    /**
     * @description
     * Return default view.
     */
    function getDefaultView() {
      return activeView;
    }

    /**
     * @description
     * Set activeView.
     */
    function setActiveView(value) {
      activeView = value;
    }
  }
})();
