(function () {
  angular.module('kmi.lms.integration').factory('limelightVideoService', limelightVideoService);

  /* @ngInject */
  function limelightVideoService(_) {
    return {
      buildVideoEmbedPath: buildVideoEmbedPath,
    };

    /**
     * @description
     * Generates path to embed a video
     * @param videoId
     * @param options
     * @returns {string}
     */
    function buildVideoEmbedPath(videoId, options) {
      angular.extend(options || {}, {
        playerForm: 'Player',
        embedMode: 'html',
      });

      options = processOptions(options);

      return ['//link.videoplatform.limelight.com/media/?mediaId=', videoId, '&', options].join('');
    }

    function processOptions(options) {
      var optionsList = [];

      if (!options) {
        return '';
      }

      _.forEach(options, function (value, key) {
        optionsList.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      });

      return optionsList.join('&');
    }
  }
})();
