(function () {
  angular.module('kmi.lms.passwords').component('passwordInput', {
    template: require('ajs/modules/passwords/components/password-input.html').default,
    controller: PasswordInputController,
    controllerAs: 'vm',
    bindings: {
      user: '=',
      submitted: '=',
      showOnlyFailedPolicies: '<?',
    },
  });

  /* @ngInject */
  function PasswordInputController($scope) {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      $scope.$on('event:passwordValidationStart', function () {
        vm.passwordValidationForm.password.$validate();
      });
    }
  }
})();
