(function () {
  angular.module('kmi.lms.network').component('communityDetailsComponent', {
    template: require('ajs/modules/network/summary/community-details.html').default,
    controller: CommunityDetailsComponentController,
    controllerAs: 'vm',
    bindings: {
      trackingName: '<?',
    },
  });

  function CommunityDetailsComponentController(
    currentUser,
    securityService,
    userSettingsService,
    communitySummaryService,
  ) {
    var vm = this;

    vm.currentUser = currentUser.get();
    vm.networkAvailable =
      securityService.isStateAvailable('main.user_network') &&
      userSettingsService.user_network_enabled &&
      vm.currentUser.userNetwork &&
      vm.currentUser.userNetwork.profile;

    this.$onInit = onInit;

    function onInit() {
      vm.trackingName = vm.trackingName + '_curriculum_summary';
      communitySummaryService.getSummaryForUser().then(function (summary) {
        vm.summaryDetails = summary;
      });
    }
  }
})();
