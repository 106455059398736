(function () {
  angular.module('kmi.lms.network').factory('networkFollowersService', networkFollowersService);

  /* @ngInject */
  function networkFollowersService($http, $q) {
    var followedUsersData = null,
      suggestedUsersData = null,
      cachedUsersData = false,
      followedUsersParams = null;

    return {
      getFollowedUsers: getFollowedUsers,
      getSuggestedUsers: getSuggestedUsers,
      setCacheFlag: setCacheFlag,
      getCacheFlag: getCacheFlag,
      clearCachedData: clearCachedData,
      getCachedUsersData: getCachedUsersData,
      setCachedUsersData: setCachedUsersData,
      getUsersParams: getUsersParams,
    };

    function getFollowedUsers(params, cached) {
      if (cached && followedUsersData) {
        var deferred = $q.defer();
        deferred.resolve(followedUsersData);
        return deferred.promise;
      } else {
        followedUsersParams = params;
        return $http
          .get(['/a/user/user_network/users/me/followed_users/'].join(''), { params: params })
          .then(function (response) {
            followedUsersData = response.data;
            return response.data;
          });
      }
    }

    function getSuggestedUsers(params, cached) {
      if (cached && suggestedUsersData) {
        var deferred = $q.defer();
        deferred.resolve(suggestedUsersData);
        return deferred.promise;
      } else {
        return $http
          .get(['/a/user/user_network/users/me/suggested-users/'].join(''), { params: params })
          .then(function (response) {
            suggestedUsersData = response.data;
            return response.data;
          });
      }
    }

    function setCacheFlag(value) {
      cachedUsersData = value;
    }

    function getCacheFlag(type) {
      if (type === 'suggested') {
        return cachedUsersData && !!suggestedUsersData;
      } else {
        return cachedUsersData && !!followedUsersData;
      }
    }

    function clearCachedData() {
      followedUsersData = null;
      suggestedUsersData = null;
    }

    function getCachedUsersData(type) {
      if (type === 'suggested') {
        return suggestedUsersData;
      } else {
        return followedUsersData;
      }
    }

    function setCachedUsersData(type, value) {
      if (type === 'suggested') {
        suggestedUsersData = value;
      } else {
        followedUsersData = value;
      }
    }

    function getUsersParams() {
      return followedUsersParams;
    }
  }
})();
